// Package Import
import { useSimpleList } from '@refinedev/antd';
import { useTokenContext } from 'contexts/resource/ResourceContext';

// Project Import
import apiEndpoint from 'core/common/apiEndPoints';

export function getOrganizationName(orgid) {
  // const { listProps: orgData } = useSimpleList({
  //     resource: apiEndpoint.organization,
  //     dataProviderName: 'programDataProvider',
  //     pagination: {
  //       mode: 'off',
  //     },
  //   });
  //   const orgDataList = (orgData?.dataSource as any)?.results
  const { organizationList } = useTokenContext();
  return organizationList?.find((org) => org?.value === orgid)?.label;
}

export function getIpRoleName(ipRoleList) {
  const { listProps: ipData } = useSimpleList({
    dataProviderName: 'organizationDataProvider',
    resource: `${apiEndpoint.incentiveProgram}/?skip_user_permission=true&frontend_app=user_management`,
    pagination: {
      mode: 'off',
    },
  });
  const ipDataList = (ipData?.dataSource as any)?.results;
  const { userRoleList } = useTokenContext();

  function getIpName(ipid) {
    return ipDataList?.find((ip) => ip?.unique_id === ipid)?.iprogram_name;
  }
  function getRoleName(roleid) {
    return userRoleList?.find((role) => role?.value === roleid)?.label;
  }
  return ipRoleList?.map((iprole) => ({
    ...iprole,
    iprogram_name: getIpName(iprole?.iprogram_id),
    role_name: getRoleName(iprole?.role_id),
  }));
}
