import horiLogo from '../../assets/images/horiLogo.png';
import microsoftExcel from '../../assets/images/microsoftExcel.png';
import veriLogo from '../../assets/images/vertiLogo.jpeg';
import enflag from '../../assets/images/flags/en.svg';
import deflag from '../../assets/images/flags/de.svg';
import esflag from '../../assets/images/flags/es.svg';
import hiflag from '../../assets/images/flags/hi.svg';
import augment8SmallLogo from '../../assets/images/augment8SmallLogo.png';
import augmentLogo from '../../assets/images/augmentLogo.jpg';
import augmentNewLogo from '../../assets/images/augmentNewLogo.png';
import UserManagement from '../../assets/images/icons/user.png';
import DataSourceManagement from '../../assets/images/icons/data_source.png';
import ProgramManagement from '../../assets/images/icons/program.png';
import WorkflowManagement from '../../assets/images/icons/workflow.png';
import PayoutManagement from '../../assets/images/icons/payout.png';

export default function imageFlag(type) {
  let flag;
  switch (type) {
    case 'de':
      flag = deflag;
      break;
    case 'es':
      flag = esflag;
      break;
    case 'hi':
      flag = hiflag;
      break;

    default:
      flag = enflag;
  }

  return flag;
}

export const Images = {
  horiLogo,
  veriLogo,
  microsoftExcel,
  augment8SmallLogo,
  augmentLogo,
  augmentNewLogo,
  UserManagement,
  DataSourceManagement,
  ProgramManagement,
  WorkflowManagement,
  PayoutManagement,
};
